<template>
  <div>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">{{ $t('label.search') }}</label>
          <b-form-input
              v-model="searchTerm"
              :placeholder="$t('label.searchPlaceholder')"
              type="text"
              class="d-inline-block"
              @input="globalSearch"
          />
        </div>
      </b-form-group>
    </div>
    <b-overlay
        :show="loading"
        :opacity="0.1"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
          @on-row-click="onRowClick"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
      >
        <div slot="emptystate">
          <div class="vgt-center-align vgt-text-disabled">
            {{ $t('table.list.empty') }}
          </div>
        </div>
        <template
            slot="table-row"
            slot-scope="props"
        >

          <!-- Column: Name -->
          <span
              v-if="props.column.field === 'title'"
              class="text-nowrap"
          >
                <span class="text-nowrap">{{ props.row.title }}</span>
              </span>

          <span
              v-else-if="props.column.field === 'language'"
              class="text-nowrap"
          >
                <span class="text-nowrap">{{ $t('emailTemplates.languages.'+props.row.language) }}</span>
              </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
                <span v-if="$acl.canEditPermission(role)">
                  <b-button
                      size="sm"
                      variant="primary"
                      class="ml-1"
                      v-b-tooltip.hover
                      :title="$t('label.button.modify')"
                      @click.stop="$router.push({ name: 'modify-email-template', params: { id: props.row.id }})"
                  >
                    <feather-icon
                        icon="EditIcon"
                    />
                  </b-button>
                </span>
              </span>

          <!-- Column: Common -->
          <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ ((page - 1) * pageLength) + 1 }} +
            </span>
              <b-form-select
                  v-model="pageLength"
                  :options="pages"
                  class="mx-1"
                  @change="handlePageChange"
              />
              <span class="text-nowrap "> a {{ dataFromServer.total }} találatból </span>
            </div>
            <div>
              <b-pagination
                  :value="1"
                  :total-rows="dataFromServer.total"
                  :per-page="dataFromServer.per_page"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </div>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BOverlay, VBTooltip
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      role: 'emailTemplate',
      pageLength: 10,
      dir: false,
      pages: ['10', '20', '50'],
      columns: [
        {
          label: this.$t('form.id'),
          field: 'id',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: this.$t('table.filter.placeholder.id'),
          },
          width: '5%',
        },
        {
          label: this.$t('form.language'),
          field: 'language',
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: this.$t('table.filter.placeholder.language'),
            filterDropdownItems: [
              {value: 'hu', text: this.$t('emailTemplates.languages.hu')},
              {value: 'en', text: this.$t('emailTemplates.languages.en')},
              {value: 'de', text: this.$t('emailTemplates.languages.de')},
            ],
          },
        },
        {
          label: this.$t('form.title'),
          field: 'title',
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: this.$t('table.filter.placeholder.title'),
          },
        },
        {
          label: this.$t('form.greeting'),
          field: 'greeting',
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: this.$t('table.filter.placeholder.greeting'),
          },
        },
        {
          label: this.$t('form.subject'),
          field: 'subject',
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: this.$t('table.filter.placeholder.subject'),
          },
        },
      ],
      rows: [],
      page: 1,
      dataFromServer: [],
      filterData: [],
      searchTerm: '',
      sortBy: {
        field: 'title',
        type: 'asc',
      },
      columnFilters: [],
      loading: false,
    }
  },
  created() {
    if (this.$acl.canEditPermission(this.role)) {
      const actions = [
        {
          label: this.$t('table.header.actions'),
          field: 'action',
          sortable: false,
          width: '10rem',
          tdClass: 'text-center'
        },
      ]

      this.columns = [...this.columns, ...actions]
    }

    this.initData()
  },
  methods: {
    initData() {
      this.loading = true
      const searchParams = {
        page: parseInt(this.page),
        limit: parseInt(this.pageLength),
        query: this.searchTerm,
        columnFilters: this.columnFilters,
        sort: this.sortBy,
      }

      this.$store.dispatch('fetchEmailTemplates', searchParams).then(response => {
        this.rows = response.data.entities.data
        this.dataFromServer = response.data.entities
        this.page = this.dataFromServer.current_page
        this.pageLength = parseInt(this.dataFromServer.per_page)
      }).finally(() => {
        this.loading = false
      })
    },
    onRowClick(params) {
      if (this.$acl.canEditPermission(this.role))
        this.$router.push({name: 'modify-email-template', params: {id: params.row.id}})
    },
    handleChangePage(page) {
      this.page = page
      this.initData()
    },
    handlePageChange(active) {
      this.pageLength = active
      this.page = 1
      this.handleChangePage()
    },
    globalSearch(params) {
      this.page = 1
      this.query = params.searchTerm
      this.initData()
    },
    onSortChange(params) {
      this.page = 1
      this.sortBy = params[0]
      this.initData()
    },
    onColumnFilter(params) {
      this.page = 1
      this.columnFilters = params.columnFilters
      this.initData()
    },
  },
}
</script>
