<template>
  <div>
    <b-card no-title>
      <b-row>
        <b-col
            xs="12"
            class="mx-auto"
        >
          <b-card-title>
            {{ $t('card.title.list.emailTemplate') }}
          </b-card-title>
        </b-col>
      </b-row>
      <b-card-text>
        {{ $t('card.description.list.emailTemplate') }}
      </b-card-text>
    </b-card>

    <b-card>
      <b-row>
        <b-col
            xs="12"
            class="mx-auto"
        >
          <table-mobile
              v-if="this.$helpers.isMobileResolution() || this.$helpers.isSmallResolution()"
          />
          <table-desktop
              v-else
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardTitle, BCardText, BButton
} from 'bootstrap-vue'
import tableDesktop from './table/Desktop.vue'
import tableMobile from './table/Mobile.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BButton,
    tableDesktop,
    tableMobile,
  },
  data() {
    return {
      role: 'emailTemplate',
    }
  },
}
</script>
